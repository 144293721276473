<!--企业展示-->
<template>
  <div class="enterprise w-1400">
    <el-row :gutter="30">
      <el-col :span="17">
        <div class="main-l" v-loading="loading">
          <div class="header">
            <div class="fl">
              <h3 class="fl">企业类型：</h3>
              <ul class="fl clearfix">
                <li
                  class="fl header-list"
                  :class="{ isact: !params.filter.enterpriseType }"
                  @click="
                    params.filter.enterpriseType = '';
                    getPageList();
                  "
                >
                  全部
                </li>
                <li
                  v-for="item in qylx"
                  class="fl header-list"
                  :key="item"
                  :class="{ isact: params.filter.enterpriseType == item }"
                  @click="
                    params.filter.enterpriseType = item;
                    getPageList();
                  "
                >
                  {{ item }}
                </li>

                <li class="fl header-list row">
                  <div>地区：</div>
                  <!-- <el-cascader
                    
                    size="mini"
                    :options="regionCodeOptions"
                    clearable
                    filterable
                  >
                  </el-cascader> -->
                    <el-select style="width: 150px" size="mini" v-model="value" placeholder="请选择" @change="regionhandleChange">                    
                      <el-option
                        v-for="item in regionCodeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                </li>
              </ul>
            </div>
            <!-- <div class="fr more">更多<i class="el-icon-arrow-right"></i></div> -->
          </div>
          <div
            class="card clearfix"
            v-for="item in pageData.list"
            :key="item.id"
          >
            <router-link :to="`/enterprisedetail?id=${item.id}&oParams=${JSON.stringify(paramsNow)}`">
              <div class="fl card-pic">
                <el-image
                  :src="item.photoUrls[0]"
                  style="width: 100%; height: 100%"
                  fit="cover"
                ></el-image>
              </div>
              <div class="fl card-info">
                <div class="card-name">
                  企业名称：<span>{{ item.name }}</span>
                </div>
                <div class="card-name">
                  企业地址：<span>{{ item.address }}</span>
                </div>
                <div class="card-intro">
                  企业简介：<span v-html="item.introduction"></span>
                </div>
                <div class="card-phone">
                  电话：<span>{{ item.telephone}}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <el-pagination
          class="pagination"
          style="margin-top: 16px"
          background
          layout="prev, pager, next,jumper"
          :total="pageData.total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-col>
      <el-col :span="7">
        <div class="main-r">
          <div class="header">
            <div class="fl">
              <h3 class="fl">产品推荐</h3>
            </div>
            <router-link :to="`/productlist`">
              <div class="fr more">更多<i class="el-icon-arrow-right"></i></div>
            </router-link>
          </div>
          <div
            class="card2 clearfix"
            v-for="item in propageData"
            :key="item.id + 'card'"
          >
         <router-link :to="`/productdetail?id=${item.id}`">
            <div class="fl card2-pic">
                <el-image
                  :src="item.photoUrls[0]"
                  style="width: 100%; height: 100%"
                  fit="cover"
                ></el-image>
              
            </div>
            <div class="fl card2-info">
              <div class="card2-name ellipsis">{{ item.name }}</div>
              <div class="card2-intro">
                {{ item.introduction }}
              </div>
              <!-- <div class="card-time">2021-10-09 浏览量 248</div> -->
            </div>
          </router-link>

          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { GetEnterpriseInfos } from "@/api/xiangningwang";
import { GetProductPage } from "@/api/home";
import regionData from "@/data/xiningregion.js";

export default {
  data() {
    return {
      qylx: ["龙头企业", "农民合作社省级示范社", "一般企业"],
      params: {
        currentPage: 1,
        pageSize: 10,
        filter: {
          name: "",
          enterpriseType: "",
          regionCode: "",
        },
      },
      paramsNow:{},
      value:'',
      searchkey: {},
      pageData: {},
      propageData: {},
      loading:false,
      regionCodeOptions: regionData.region[0].children,
    };
  },
  computed: {
    baseUrl: () => process.env.VUE_APP_PHOTO_URL,
    regHtml: () => regHtml,
  },
  methods: {
    getPageList() {
      let params = JSON.parse(JSON.stringify(this.params))
      if(params.filter.enterpriseType == "农民合作社省级示范社"){
        params.filter.enterpriseType = "省级合作社"
      }
      this.paramsNow = params;
      this.loading = true;
      GetEnterpriseInfos(params).then((res) => {
        const data = res.data.data;
        data.list.forEach((d) => {
          d.photoUrls = d.photoUrls.split(",");
        });
        this.pageData = data;
        this.loading = false
      });
    },
    //分页
    handleCurrentChange(page) {
      this.params.currentPage = page;
      this.getPageList();
    },
    regionhandleChange(value) {
      this.params.filter.regionCode = value;
      this.getPageList();
    },

    getProductPages() {
      const proparams = { currentPage: 1, pageSize: 10 };
      GetProductPage(proparams).then((res) => {
        //this.propageData = res.data.data.list;
        const data = res.data.data.list;
        data.forEach((element) => {
          element.photoUrls = element.photoUrls.split(",");
        });
        this.propageData = data;
      });
    },
  },
  mounted() {
    this.getPageList();
    this.getProductPages();
  },
};
</script>
<style scoped>
.card-time {
  font-size: 16px;
  line-height: 65px;
  padding-left: 50px;
}
.card-intro span {
  font-weight: 500;
}
.card-intro {
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  /* min-height: 60px; */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-phone{
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
}
.card-phone span{
  font-weight: 500;
}
.card2-intro {
  text-indent: 20px;
  font-size: 16px;
  line-height: 20px;
  /* min-height: 60px; */
  font-size: "微软雅黑";
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
.card-name {
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
}
.card2-name {
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
}
.card-info {
  width: calc(100% - 255px);
  margin-left: 18px;
}
.card-info span {
  color: #777;
}
.card2-info {
  width: calc(100% - 190px);
  margin-left: 10px;
  overflow: hidden;
}
.card-pic {
  width: 235px;
  height: 175px;
  overflow: hidden;
}
.card2-pic {
  width: 180px;
  height: 175px;
  overflow: hidden;
}
.card,
.card2 {
  padding: 20px;
  border-bottom: 1px solid #e4e7ed;
}
.isactive {
  background-color: #0069c9;
  cursor: pointer;
}
.more {
  margin-top: 3px;
  cursor: pointer;
}
.main-l,
.main-r {
  border: 1px solid #e4e7ed;
}
.header-list.isact {
  background: #0069c9;
  color: #fff;
}
.header-list {
  padding: 3px 10px;
  /* background: #fff; */
  /* margin: 0 10px; */
  box-sizing: border-box;
  /* font-size: 15px; */
}
.header-list:not(.header-list:last-child){
  cursor: pointer;
}
.header h3 {
  padding: 3px 12px;
}
.header {
  height: 50px;
  background: #dfdfdf;
  padding-top: 12px;
  font-size: 20px;
  border-bottom: 1px solid #e4e7ed;
}
</style>
